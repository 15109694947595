<template>
  <v-container fluid v-if="!isLoading">
    <v-card>
      <v-card-title primary-title class="blue white--text">
        <div>
          <h3 class="headline mb-0">Alta Nueva Ficha</h3>
        </div>
      </v-card-title>
      <v-divider class="mb-2"></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="validForm">
          <v-row dense>
            <v-col cols="12" md="7">
              <v-text-field
                v-model="misDatos.reco_name"
                label="Nombre Comercial"
                :rules="[rules.required]"
                outlined
                dense
                autocomplete="new-name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :counter="10"
                :rules="[v => requeridoConAlta(v, 'cif')]"
                dense
                label="CIF"
                outlined
                autocomplete="new-cif"
                v-model="misDatos.reco_cif"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="misDatos.reco_email"
                label="E-mail"
                :rules="[v => requeridoConAlta(v, 'email')]"
                outlined
                dense
                autocomplete="new-email"
              ></v-text-field>
            </v-col>
          </v-row>
          <!--    <v-row dense>
            <v-col cols="12" md="12">
              <v-card text outlined tile>
                <v-card-title>
                  <h5>Representante</h5>
                </v-card-title>
                <v-card-text class="text--primary"> -->
          <v-row dense>
            <v-col cols="12" md="5">
              <v-text-field
                  dense
                  label="Nombre Fiscal"
                  outlined
                  v-model="misDatos.reco_namefis"
                  autocomplete="new"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="misDatos.reco_agentname"
                label="Representante"
                required
                outlined
                dense
                autocomplete="new-n"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="misDatos.reco_agentdni"
                label="DNI Representante"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <!--         </v-card-text>
              </v-card>
            </v-col>
          </v-row> -->

          <v-row dense>
            <v-col>
              <v-text-field
                label="Alta Contrato"
                v-model="misDatos.reco_contractdate"
                type="date"
                outlined
                dense
                autocomplete="new-n"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Fin Contrato"
                v-model="misDatos.reco_notificationdate"
                type="date"
                outlined
                dense
                autocomplete="new-n"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="misDatos.reco_idstatus"
                label="Estado"
                :items="compEstados"
                item-text="stat_name"
                item-value="stat_id"
                :rules="[rules.required]"
                outlined
                dense
                autocomplete="new-n"
                :menu-props="{
                  closeOnContentClick: true,
                  offsetY: true,
                  maxHeight: 650
                }"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <!--   <v-col cols="12" md="3">
              <v-select
                v-model="misDatos.reco_idticen"
                label="Tipo de Centro"
                :items="tipoCentro"
                item-text="tipo"
                item-value="id"
                :rules="[rules.required]"
                outlined
                dense
                autocomplete="new-n"
              ></v-select>
            </v-col> -->
            <v-col cols="12" md="12">
              <v-text-field
                v-model="misDatos.reco_archivo"
                label="Archivo"
                outlined
                dense
                counter="200"
                hint="Máximo 200 caracteres"
                :maxlength="200"
                autocomplete="new-n"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- ingresamos al menos una dirección -->
          <v-row dense>
            <v-col cols="12" md="12">
              <v-card text outlined tile>
                <v-card-title>
                  <!--  <v-col cols="12" class="px-0">
                    <v-alert dense type="info" color="pink darken-1">
                      Para poder registrar el alta Es obligatorio agregar una
                      <b>dirección</b>, en el siguiente formulario podrá
                      registrar cuantas sean necesarias...
                    </v-alert>
                  </v-col> -->
                </v-card-title>
                <v-card-text class="text--primary">
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="misDatos.address.read_address"
                        label="Dirección"
                        required
                        outlined
                        dense
                        autocomplete="new-direccion"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :rules="[rules.required]"
                        v-model="misDatos.address.read_phone"
                        label="Teléfono"
                        required
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <!-- PASO POR EL PROP SELECTED TODOS LOS DATOS RELATIVOS A LA DIRECCION -->
                      <zipcodes-desp
                        @emitSeleccion="selectedZip"
                        :selected="misDatos.address"
                        :requerido="misDatos.reco_idstatus === 6"
                      ></zipcodes-desp>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions justify-end>
        <v-container fluid class="pa-0 ma-0">
          <v-btn
            color="blue"
            class="float-right mx-1 white--text"
            @click="crearFicha"
            >continuar <v-icon right>mdi-arrow-right-box</v-icon></v-btn
          >
          <v-btn
            outlined
            color="secondary"
            class="float-right mx-1"
            @click="
              $router.push({ name: 'fichas', params: { localdata: true } })
            "
            >cancelar</v-btn
          >
        </v-container>
      </v-card-actions>
    </v-card>
    <!-- dialogo clonar -->
    <dialog-aceptar
      :mostrar="dialogAceptar.mostrar"
      @emitAceptar="enviarficha"
      @emitCancelar="dialogAceptar.mostrar = false"
      :cabecera="dialogAceptar.cabecera"
      :cuerpo="dialogAceptar.cuerpo"
      :maxWidth="550"
    ></dialog-aceptar>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ZipcodesDesp from "@/components/comun/ZipcodesDesp";
import DialogAceptar from "@/components/comun/Dialog";

export default {
  name: "ficha-detalle-general",
  props: ["datos", "estado"],
  components: {
    ZipcodesDesp,
    DialogAceptar
  },
  data: () => ({
    dialogAceptar: {
      mostrar: false,
      cabecera: "Aviso",
      cuerpo:
        "Va a proceder a dar de alta una ficha sin dirección, ¿Desea continuar?"
    },
    validForm: true,
    isLoading: true,
    estados: null,
    tipoCentro: [
      { id: 1, tipo: "Oficina" },
      { id: 2, tipo: "Acuerdo Nacional" },
      { id: 3, tipo: "Resto de Centros" }
    ],
    misDatos: {
      reco_name: null,
      reco_namefis: null,
      reco_observaciones: null,
      reco_phone: null,
      reco_cif: null,
      reco_email: null,
      reco_address: null,
      reco_idstatus: null,
      reco_idcp: null,
      reco_idticen: null,
      reco_agentname: null,
      reco_agentdni: null,
      reco_archivo: null,
      reco_contractdate: null,
      reco_notificationdate: null,
      record_zip_code: null,
      address: {
        read_address: null,
        read_phone: null,
        read_idcp: null
      }
    },
    rules: {
      required: value => !!value || "Requerido.",
      counter: value => value.length <= 20 || "Max 20 characters",
      email: value => {
        const pattern = /.+@.+\..+/;
        return pattern.test(value) || "e-mail no válido.";
      },

      minChars: value => (value && value.length >= 8) || "Mínimo 8 caracteres"
    }
  }),
  methods: {
    ...mapActions("records", ["setStatusesAll", "setRecordCreate"]),
    /* funcion para generar la validacion dinámica en base al estado*/
    requeridoConAlta(val, t) {
      // si tenemos estado = alta
      if (this.misDatos.reco_idstatus === 6) {
        switch (t) {
          case "email": {
            if (val) {
              const pattern = /.+@.+\..+/;
              return pattern.test(val) || "e-mail no válido.";
            }
            else
              return true;
          }
          case "cif": {
            if (val) {
              return (val && val.length >= 8) || "Mínimo 8 caracteres";
            }
            else
              return true;
          }
        }
      } else if (val) {
        // si no es necesario alta, validamos que los datos sean correctos
        switch (t) {
          case "email": {
            const pattern = /.+@.+\..+/;
            return pattern.test(val) || "e-mail no válido.";
          }
          case "cif": {
            return (val && val.length >= 8) || "Mínimo 8 caracteres";
          }
        }
      } else {
        return true;
      }
    },
    /* funcion para recuperar los datos del componente zipcodes */
    selectedZip(e) {
      this.misDatos.read_idcp = e;
    },
    /* funcion parar guardar una ficha */
    crearFicha() {
      //validamos el form antes de nada
      this.$refs.form.validate();
      if (!this.validForm) {
        return;
      }
      // comprobamos si no hay dirección
      /*  address: {
        read_address: null,
        read_phone: null,
        read_idcp: null
      } */
      if (
        !this.misDatos.address.read_address ||
        !this.misDatos.address.read_idcp
      ) {
        this.dialogAceptar.mostrar = true;
      } else {
        this.enviarficha();
      }
    },
    /* función para proceder a guardar una ficha si hay aceptación del modal */
    enviarficha() {
      // si entro del modal
      this.dialogAceptar.mostrar = false;

      this.setRecordCreate(this.misDatos).then(res => {
        this.$router.push({ name: "fdetalle", params: { id: res.reco_id } });
      });
    },
    cargarEstados() {
      this.setStatusesAll().then(res => {
        this.estados = res.filter(e => e.stat_id !== 1);
        this.isLoading = false;
      });
    }
  },
  computed: {
    compEstados() {
      if (!this.estados) {
        return [];
      }
      return this.estados;
    }
  },
  mounted() {
    //cargamos la lista de estados
    this.cargarEstados();
  }
};
</script>

<style scoped>
/* .v-input {
  font-size: 0.9em;
} */
</style>
